export function goLink(url) {
    return this.$copyText(url).then(
        (e) => {
            return this.$message({
                message: '复制成功',
                type: 'success',
            })
        },

    )
}