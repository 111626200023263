export function jumpLink(item) {
    let a = document.createElement('a')
    a.href = item.case_url
    a.target = "_blank"
    a.setAttribute('referrerpolicy','no-referrer')
    // a.referrerpolicy = "no-referrer"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    // window.open(item.case_url, '_blank');
}