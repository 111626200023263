import { curlGet } from "@/api/request"
import $util from "./util"
export function getUserInfo() {
    curlGet('/api/user/info').then((res) => {
        if (res.data.code) {
            this.$user_info = res.data.data
            
            $util.setLocalStorage('user_info', res.data.data) // 写入local storage
        }
    })
}