<template>
  <div>
    <el-divider content-position="left">特殊类目</el-divider>
  </div>
</template>

<script>
  export default {
    name:'',
    created(){},
    mounted(){},
    data(){
      return{}
    },
    methods:{},
 }
</script>

<style lang=scss scoped>
::v-deep .el-divider__text{
    font-size: 14px;
}
</style>