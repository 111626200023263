import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
import mediaOrder from './mediaOrder'
import manuscript from './manuscript'
import packOrder from './packOrder'
export default new Vuex.Store({
  plugins: [
    // 把vuex的数据存储到sessionStorage    
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],//会自动保存创建的状态。刷新还在
  modules: {
    // 媒介套餐导航
    mediaOrder: mediaOrder,
    manuscript: manuscript,
    packOrder: packOrder,
  },
  getters: {
    stateDate(state) {
      console.log(state);
      return
    },
  },
  state: {
    navShow: 0,
    userInfo: {},
    // 登录弹窗
    loginShow: false,
    // 忘记密码弹窗
    passwordShow: false,
    MediaNav: [],
    Media: [],
    // 媒体资源
    TypeItem: {}, //媒体资源选项卡
    shopObj: [],
    navList: [
      {
        title: '首页',
        imgUrl: require('../assets/user/home.png'),
        imgUrl2: require('../assets/user/homeHover.png'),
      },
      {
        title: '产品中心',
        imgUrl: require('../assets/user/media.png'),
        imgUrl2: require('../assets/user/mediaHover.png'),
        selectStatus: false,

        children: [
          {
            title: '媒介套餐',
          },
          {
            title: '媒介资源',
          },
          {
            title: '一言一文',
          },
          {
            title: '享视界',
          },
          // {
          //   title: '草稿箱',
          // },
        ],
      },
      {
        title: '稿件发布',
        imgUrl: require('../assets/user/manuscript.png'),
        imgUrl2: require('../assets/user/manuscript.png'),
        selectStatus: false,

        children: [
          {
            id: 1,
            title: '资讯门户发布',
          },
          {
            id: 2,
            title: '自媒体发布',
          },
          {
            id: 3,
            title: '纸媒介发布',
          },
          {
            id: 4,
            title: '公众号发布',
          },

          {
            id: 5,
            title: '微博发布',
          },
          {
            id: 6,
            title: '小红书发布',
          },
          {
            id: 7,
            title: '短视频发布',
          },
          {
            id: 8,
            title: '套餐发布'
          },
          {
            title: '草稿箱',
          },
        ],
      },

      {
        title: '订单管理',
        imgUrl: require('../assets/user/order.png'),
        imgUrl2: require('../assets/user/orderHover.png'),
        selectStatus: false,

        children: [
          {
            id: 'order',
            order_status_total_num: 0,
            title: '媒介订单',
          },
          {
            id: 'package',
            package_status_total_num: 0,
            title: '套餐订单',
          },
          {
            title: 'AI记录',
          },
          {
            id: '订单管理-我的工单',
            title: '我的工单',
          },
        ],
      },
      {
        title: '我的媒介',
        imgUrl: require('../assets/user/MyMedia.png'),
        imgUrl2: require('../assets/user/MyMediaHover.png'),
        selectStatus: false,

        children: [
          {
            title: '我的资源',
          },
          {
            id: 'draft',
            draft_status_total_num: 0,
            title: '我的收稿',
          },
          {
            title: '售后记录',
          },
        ],
      },

      {
        title: '财务管理',
        imgUrl: require('../assets/user/money.png'),
        imgUrl2: require('../assets/user/money.png'),
        selectStatus: false,

        children: [
          {
            title: '在线充值',
          },
          {
            title: '提现管理',
          },
          {
            title: '账单细明',
          },
          {
            title: '发票列表',
          },
          {
            title: '申请发票',
          },
          {
            title: '钱包转换',
          },
        ],
      },
      {
        title: '账户管理',
        imgUrl: require('../assets/user/user.png'),
        imgUrl2: require('../assets/user/userHover.png'),
        selectStatus: false,

        children: [
          {
            title: '消息中心',
          },
          {
            title: '资料密码',
          },
          {
            title: '企业认证',
          },
          {
            title: '平台帮助',
          },
        ],
      },
    ],
    activeTitle: '首页',
    // 会员中心子导航跳转样式 --//判断是否符合判断条件
    ActiveChild: '',
    // 添加媒体-请求分类
    active: 1,
    AddRs: 0,//添加媒体导航id
    // AddRsId:undefined, //添加媒体-媒体id
    /*----------------------------------------------- */
    UserMediaNav: [], //会员中心-媒介选择-导航
    UserMedia: [], // 会员中心-媒介选择-筛选项
    UserTypeItem: {
      id: 1,
      title: '新闻媒体'
    },
    /*媒介/套餐下单 */
    FillForm: {
      cover_image: '', //封面
      title: '',
      content: '', //编译器内容
      limit_time: '', //时间选择器
      file: '',
      url: '',
      video_url: '',
      original_url: '', //原文链接
      remark: '',
      medium_ids: '',
      package_ids: '',
    },
    /*----------发票---------- */
    ApplyForInvoiceTitle: '申请发票',
    /*-----------------发布稿件_是否已经选择媒体-------------*/
    fillActive: true,
    /* 我的媒介导航栏选中*/
    pubNav: 1,


  },
  mutations: {
    shopPush(state, n) {
      this.state.shopObj = []
      console.log(n);
      n.forEach((element, index) => {
        return this.state.shopObj.push({
          category_id: element.category_id ? element.category_id : 0,
          id: element.id,
          title: element.title,
          medium_price: element.price,
          medium_ids: element.category_id ? element.id : 0,
          package_ids: element.category_id ? element.id : 0,
          check: element.check
        })
      });

    },
    // 个人中心导航切换
    childTab(state, title) {
      this.state.ActiveChild = title
      for (let i = 0; i < this.state.navList.length; i++) {
        if (this.state.navList[i].children) {
          for (let j = 0; j < this.state.navList[i].children.length; j++) {
            if (this.state.navList[i].children[j].title == title) {
              this.state.activeTitle = this.state.navList[i].title
              this.state.navList[i].selectStatus = true
            }
            // if (this.state.navList[i].children[j].title != this.state.ActiveChild && this.state.navList[i].selectStatus == true) {
            //   console.log(this.state.navList[i].children[j].title);
            //   this.state.navList[i].selectStatus = false
            // }
          }
        }
      }
    }
  },
  actions: {
  },
})
