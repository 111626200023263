import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  // 媒体价格
  {
    path: '/media',
    name: 'mediaView',
    component: () => import('@/views/Media.vue'),
    children: [
      // 新闻媒体
      {
        path: 'News',
        name: 'MediaNews',
        component: () => import('@/views/Media/News.vue')
      },
      // 自媒体
      {
        path: 'WeMedia',
        name: 'MediaWeMedia',
        component: () => import('@/views/Media/WeMedia.vue')
      },
      // 纸媒体
      {
        path: 'PaperMedia',
        name: 'MediaPaperMedia',
        component: () => import('@/views/Media/PaperMedia.vue')
      },
      // 公众号
      {
        path: 'OfficialAccounts',
        name: 'MediaOfficialAccounts',
        component: () => import('@/views/Media/OfficialAccounts.vue')
      },
      // 微博
      {
        path: 'Microblog',
        name: 'Microblog',
        component: () => import('@/views/Media/Microblog.vue')
      },
      // 小红书
      {
        path: 'RedBook',
        name: 'MediaRedBook',
        component: () => import('@/views/Media/RedBook.vue')
      },
      // 短视频
      {
        path: 'ShortVideo',
        name: 'MediaShortVideo',
        component: () => import('@/views/Media/ShortVideo.vue')
      },
      // 媒体套餐
      {
        path: 'SetMedia',
        name: 'SetMedia',
        component: () => import('@/views/Media/SetMedia.vue')
      },
    ]
  },
  // 关于我们
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    meta: '注册',
    component: () => import('@/views/Register.vue')
  },
  // 结算
  {
    path: '/close',
    name: 'close',
    component: () => import('@/views/Close.vue')
  },
  // 一言一文
  {
    path: '/word',
    name: 'word',
    component: () => import('@/views/Word.vue')
  },
  // 享视界
  {
    path: '/enjoy',
    name: 'enjoy',
    component: () => import('@/views/enjoy.vue')
  },
  // 产品合作
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/Product.vue')
  },
  // 可信网站
  {
    path: '/credible',
    name: 'credible',
    component: () => import('@/views/credible.vue')
  },
  // 可信网站
  {
    path: '/technical',
    name: 'technical',
    component: () => import('@/views/technical.vue')
  },
  // 技巧指南
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/Guide.vue')
  },
  // 文章详情
  {
    path: '/GuideDetail',
    name: 'GuideDetail',
    component: () => import('@/views/GuideDetail.vue')
  },
  // 一言一文操作界面
  {
    path: '/wordNav',
    name: 'wordNav',
    component: () => import('@/views/Word/index.vue')
  },
  // 企业简介
  {
    path: '/EnterpriseProfile',
    name: 'EnterpriseProfile',
    meta: '企业简介',
    component: () => import('@/views/Word/EnterpriseProfile.vue')
  },
  // 个人简介
  {
    path: '/IndividualResume',
    name: 'IndividualResume',
    meta: '个人简介',
    component: () => import('@/views/Word/IndividualResume.vue')
  },
  // 产品简介
  {
    path: '/productResume',
    name: 'productResume',
    meta: '产品简介',
    component: () => import('@/views/Word/ProductResume.vue')
  },
  // 议论文
  {
    path: '/esay',
    name: 'esay',
    meta: '议论文',
    component: () => import('@/views/Word/Esay.vue')
  },
  // 记叙文
  {
    path: '/narration',
    name: 'narration',
    meta: '记叙文',
    component: () => import('@/views/Word/Narration.vue')
  },
  // 广告语
  {
    path: '/slogan',
    name: 'slogan',
    meta: '广告语',
    component: () => import('@/views/Word/Slogan.vue')
  },
  // 祝福语
  {
    path: '/greeting',
    name: 'greeting',
    meta: '祝福语',
    component: () => import('@/views/Word/Greeting.vue')
  },
  // 错别字修改
  {
    path: '/wrongly',
    name: 'wrongly',
    meta: '错别字修改',
    component: () => import('@/views/Word/Wrongly.vue')
  },
  // 标题提炼
  {
    path: '/titleExtraction',
    name: 'titleExtraction',
    meta: '标题提炼',
    component: () => import('@/views/Word/TitleExtraction.vue')
  },
  // 文稿润色
  {
    path: '/manuscriptPolishing',
    name: 'manuscriptPolishing',
    meta: '文稿润色',
    component: () => import('@/views/Word/ManuscriptPolishing.vue')
  },
  // 文稿改写
  {
    path: '/manuscriptRewriting',
    name: 'manuscriptRewriting',
    meta: '文稿改写',
    component: () => import('@/views/Word/ManuscriptRewriting.vue')
  },
  // 短视频脚本
  {
    path: '/shortVideo',
    name: 'shortVideo',
    meta: '短视频脚本',
    component: () => import('@/views/Word/ShortVideo.vue')
  },
  // 纪录片脚本
  {
    path: '/documentary',
    name: 'documentary',
    meta: '纪录片脚本',
    component: () => import('@/views/Word/Documentary.vue')
  },
  // 营销软文
  {
    path: '/article',
    name: 'article',
    meta: '营销软文',
    component: () => import('@/views/Word/article.vue')
  },
  // 说明书
  {
    path: '/instructions',
    name: 'instructions',
    meta: '说明书',
    component: () => import('@/views/Word/instructions.vue')
  },
  // 商业计划书
  {
    path: '/business',
    name: 'business',
    meta: '说明书',
    component: () => import('@/views/Word/business.vue')
  },
  // ppt
  {
    path: '/ppt',
    name: 'ppt',
    meta: 'PPT内容框架',
    component: () => import('@/views/Word/ppt.vue')
  },
  // AI小说/歌词
  {
    path: '/novel',
    name: 'novel',
    meta: 'AI小说/歌词',
    component: () => import('@/views/Word/novel.vue')
  },
  // AI产品/活动策划方案
  {
    path: '/activities',
    name: 'activities',
    meta: 'AI策划方案',
    component: () => import('@/views/Word/activities.vue')
  },
  // AI发言稿
  {
    path: '/speechdraft',
    name: 'speechdraft',
    meta: 'AI发言稿',
    component: () => import('@/views/Word/speechdraft.vue')
  },
  // AI演讲稿
  {
    path: '/speechtext',
    name: 'speechtext',
    meta: 'AI演讲稿',
    component: () => import('@/views/Word/speechtext.vue')
  },
  // 小红书文案
  {
    path: '/word_redbook',
    name: 'word_redbook',
    meta: '小红书文案',
    component: () => import('@/views/Word/word_redbook.vue')
  },
  // AI声明
  {
    path: '/ai_statement',
    name: 'ai_statement',
    meta: 'AI声明',
    component: () => import('@/views/Word/ai_statement.vue')
  },
  // AI商评
  {
    path: '/business_review',
    name: 'business_review',
    meta: 'AI商评',
    component: () => import('@/views/Word/business_review.vue')
  },
  // AI访谈
  {
    path: '/interview',
    name: 'interview',
    meta: 'AI访谈',
    component: () => import('@/views/Word/interview.vue')
  },
  // AI操作界面
  {
    path: '/aiNav',
    name: 'aiNav',
    component: () => import('@/views/ai/aiNav.vue')
  },
  // AI绘画
  {
    path: '/paint',
    name: 'paint',
    meta: 'AI绘画',
    component: () => import('@/views/ai/paint.vue')
  },
  // 一点成诗
  {
    path: '/verse',
    name: 'verse',
    meta: '一点成诗',
    component: () => import('@/views/Word/verse.vue')
  },
  // AI黑白上色
  {
    path: '/blackWhite',
    name: 'blackWhite',
    meta: '黑白图像上色',
    component: () => import('@/views/ai/blackWhite.vue')
  },
  // 图像风格转换
  {
    path: '/transImg',
    name: 'transImg',
    meta: '图片风格转换',
    component: () => import('@/views/ai/transImg.vue')
  },
  // ai背景生成
  {
    path: '/image_matting',
    name: 'image_matting',
    meta: '背景生成',
    component: () => import('@/views/ai/image_matting.vue')
  },
  // AI抠图
  {
    path: '/change_back',
    name: 'change_back',
    meta: 'AI抠图',
    component: () => import('@/views/ai/change_back.vue')
  },
  // 人物头像动漫化
  //  {
  //   path: '/cartoon',
  //   name: 'cartoon',
  //   meta: '人物头像动漫化',
  // component: () => import('@/views/ai/cartoon.vue')
  // },
  // 律师函
  {
    path: '/lawyer',
    name: 'lawyer',
    meta: '律师函',
    component: () => import('@/views/ai/lawyer.vue')
  },
  // 版权侵权告知书
  {
    path: '/tort',
    name: 'tort',
    meta: '版权侵权告知书',
    component: () => import('@/views/ai/tort.vue')
  },
  // 起诉书
  {
    path: '/prosecute',
    name: 'prosecute',
    meta: '起诉书',
    component: () => import('@/views/ai/prosecute.vue')
  },
  
  // // AI对话
  // {
  //   path: '/aiMessage',
  //   name: 'aiMessage',
  //   meta: 'AI对话',
  //   component: () => import('@/views/ai/aiMessage.vue')
  // },
  // // AI视频
  // {
  //   path: '/aiVideo',
  //   name: 'aiVideo',
  //   meta: 'AI视频',
  //   component: () => import('@/views/ai/aiVideo.vue')
  // },
  // 个人
  {
    path: '/user',
    name: '/user',
    component: () => import('@/views/User/User.vue'),
    meta: { title: '会员中心', requiresAuth: true },
    children: [
      // 个人中心
      {
        path: 'userIndex',
        name: 'userIndex',
        component: () => import('@/views/User/Index.vue'),
        meta: '首页',
      },
      // 优惠套餐
      {
        path: 'SetMenu',
        name: 'SetMenu',
        component: () => import('@/views/User/SetMenu.vue'),
        meta: '媒介选择',
        children: [
          {
            path: 'SetMenuList',
            name: 'SetMenuList',
            component: () => import('@/views/User/SetMenuList.vue'),
            meta: '优惠套餐',
          },
          {
            path: 'fill',
            name: 'fill',
            component: () => import('@/views/User/Fill.vue'),
            meta: '稿件填写',
          },
          {
            path: 'ManuscriptPreview',
            name: 'ManuscriptPreview',
            component: () => import('@/views/User/ManuscriptPreview.vue'),
            meta: '稿件预览',
          },
          {
            path: 'ManuscriptDetails',
            name: 'ManuscriptDetails',
            component: () => import('@/views/User/ManuscriptDetails.vue'),
            meta: '订单详情',
          },
        ]
      },
      // 媒介发布
      {
        path: 'userMedia',
        name: 'userMedia',
        component: () => import('@/views/User/Media.vue'),
        meta: '媒介选择-媒介发布',
        children: [
          // 新闻媒体
          {
            path: 'News',
            name: 'UserMediaNews',
            component: () => import('@/views/UserMedia/News.vue'),
          },
          // 自媒体
          {
            path: 'UserWeMedia',
            name: 'UserWeMedia',
            component: () => import('@/views/UserMedia/WeMedia.vue'),

          },
          // 纸媒体
          {
            path: 'UserPaperMedia',
            name: 'UserPaperMedia',
            component: () => import('@/views/UserMedia/PaperMedia.vue'),
          },
          // 公众号
          {
            path: 'UserOfficialAccounts',
            name: 'UserOfficialAccounts',
            component: () => import('@/views/UserMedia/OfficialAccounts.vue'),
          },
          // 微博
          {
            path: 'UserWb',
            name: 'UserWb',
            component: () => import('@/views/UserMedia/Wb.vue'),
          },
          // 小红书
          {
            path: 'UserRedBook',
            name: 'UserRedBook',
            component: () => import('@/views/UserMedia/RedBook.vue'),
          },
          // 短视频
          {
            path: 'UserShortVideo',
            name: 'UserShortVideo',
            component: () => import('@/views/UserMedia/ShortVideo.vue'),
          },
        ]

      },
      // 草稿箱
      {
        path: 'draft',
        name: 'draft',
        component: () => import('@/views/User/Draft.vue'),
        meta: '媒介选择-草稿箱',
      },
      // 媒介订单
      {
        path: 'MediaOrder',
        name: 'MediaOrder',
        component: () => import('@/views/User/MediaOrder.vue'),
        meta: '订单管理-媒介订单',
      },
      // 媒介订单-修改页
      {
        path: 'EditFill',
        name: 'EditFill',
        component: () => import('@/views/User/EditFill.vue'),
        meta: '订单管理-媒介订单',
      },
      // 套餐订单
      {
        path: 'PackageOrder',
        name: 'PackageOrder',
        component: () => import('@/views/User/PackageOrder.vue'),
        meta: '订单管理-套餐订单',
      },
      // 我的工单
      {
        path: 'MyOrder',
        name: 'MyOrder',
        component: () => import('@/views/User/MyOrder.vue'),
        meta: '订单管理-我的工单',
      },
      // 一言一文
      {
        path: 'Myword',
        name: 'Myword',
        component: () => import('@/views/User/Word.vue'),
        meta: '一言一文',
      },
      // 发布媒介
      {
        path: 'PublishingMedia',
        name: 'PublishingMedia',
        component: () => import('@/views/User/PublishingMedia.vue'),
        meta: '我的媒介-发布媒介',
        children: [
          {
            path: 'pubNews',
            component: () => import('@/views/User/pubMedia/pubNews.vue'),
          },
          {
            path: 'pubWeMedia',
            component: () => import('@/views/User/pubMedia/pubWeMedia.vue'),
          },
          {
            path: 'pubPaperMedia',
            component: () => import('@/views/User/pubMedia/pubPaperMedia.vue'),
          },
          {
            path: 'pubOfficialAccountsMedia',
            component: () => import('@/views/User/pubMedia/pubOfficialAccounts.vue'),
          },
          {
            path: 'pubWbMedia',
            component: () => import('@/views/User/pubMedia/pubWb.vue'),
          },
          {
            path: 'pubRedbookMedia',
            component: () => import('@/views/User/pubMedia/pubRedbook.vue'),
          },
          {
            path: 'pubShortVideoMedia',
            component: () => import('@/views/User/pubMedia/pubShortVideo.vue'),
          },
        ],
      },
      // 添加资源
      {
        path: 'AddResource',
        name: 'AddResource',
        component: () => import('@/views/User/AddResource.vue'),
        meta: '我的媒介-发布媒介-添加媒介',
        children: [
          // 新闻媒体
          {
            path: 'News',
            name: 'News',
            component: () => import('@/views/User/News.vue'),
          },
          // 自媒体
          {
            path: 'WeMedia',
            name: 'WeMedia',
            component: () => import('@/views/User/WeMedia.vue'),
          },
          // 纸媒体
          {
            path: 'PaperMedia',
            name: 'PaperMedia',
            component: () => import('@/views/User/PaperMedia.vue'),
          },
          // 公众号
          {
            path: 'OfficialAccounts',
            name: 'OfficialAccounts',
            component: () => import('@/views/User/OfficialAccounts.vue'),
          },
          // 微博
          {
            path: 'Wb',
            name: 'Wb',
            component: () => import('@/views/User/Wb.vue'),
          },
          // 小红书
          {
            path: 'RedBook',
            name: 'RedBook',
            component: () => import('@/views/User/RedBook.vue'),
          },
          // 短视频
          {
            path: 'ShortVideo',
            name: 'ShortVideo',
            component: () => import('@/views/User/ShortVideo.vue'),
          },
        ]
      },
      // 稿件管理
      {
        path: 'AfterSale',
        name: 'AfterSale',
        component: () => import('@/views/User/AfterSale.vue'),
        meta: '我的媒介-售后记录',
      },
      // 稿件管理
      {
        path: 'Manuscript',
        name: 'Manuscript',
        component: () => import('@/views/User/Manuscript.vue'),
        meta: '我的媒介-稿件管理',
      },
      // 在线充值
      {
        path: 'refill',
        name: 'refill',
        component: () => import('@/views/User/Refill.vue'),
        meta: '财务管理-在线充值',
      },
      // 提现管理
      {
        path: 'Withdraw',
        name: 'Withdraw',
        component: () => import('@/views/User/Withdraw.vue'),
        meta: '财务管理-提现管理',
      },
      // 充值记录-消费记录-退款记录
      {
        path: 'AccountDetails',
        name: 'AccountDetails',
        component: () => import('@/views/User/AccountDetails.vue'),
        meta: '财务管理-账单细明',
      },
      // 发票列表
      {
        path: 'Invoice',
        name: 'Invoice',
        component: () => import('@/views/User/Invoice.vue'),
        meta: '财务管理-发票列表',
      },
      // 申请发票
      {
        path: 'ApplyForInvoice',
        name: 'ApplyForInvoice',
        component: () => import('@/views/User/ApplyForInvoice.vue'),
        meta: '财务管理-申请发票',
        children: [

          {
            path: 'ApplyForInvoiceData',
            name: 'ApplyForInvoiceData',
            component: () => import('@/views/User/ApplyForInvoiceData.vue'),
          },
          {
            path: 'ApplyForInvoiceList',
            name: 'ApplyForInvoiceList',
            component: () => import('@/views/User/ApplyForInvoiceList.vue'),
          },
        ]
      },
      // 添加开票资料
      {
        path: 'AddInvoice',
        name: 'AddInvoice',
        component: () => import('@/views/User/AddInvoice.vue'),
        meta: '财务管理-发票列表-添加开票资料',
      },
      // 钱包转换
      {
        path: 'WalletConversion',
        name: 'WalletConversion',
        component: () => import('@/views/User/WalletConversion.vue'),
        meta: '财务管理-发票列表-钱包转换',
      },
      // 消息中心
      {
        path: 'MessageCenter',
        name: 'MessageCenter',
        component: () => import('@/views/User/MessageCenter.vue'),
        meta: '财务管理-账户管理-消息中心',
      },
      // 资料密码
      {
        path: 'Resource',
        name: 'Resource',
        component: () => import('@/views/User/Resource.vue'),
        meta: '财务管理-账户管理-资料密码',
      },
      // 企业认证
      {
        path: 'Enterprise',
        name: 'Enterprise',
        component: () => import('@/views/User/Enterprise.vue'),
        meta: '财务管理-账户管理-企业认证',
      },
      // 平台帮助
      {
        path: 'Help',
        name: 'Help',
        component: () => import('@/views/User/Help.vue'),
        meta: '财务管理-账户管理-平台帮助',
      },
      // 再次发稿
      {
        path: 'Orfill',
        name: 'Orfill',
        component: () => import('@/views/User/MediaFill.vue'),
        meta: '稿件填写',
      },
      // 文章详情
      {
        path: 'TextDetails',
        name: 'TextDetails',
        meta: '文章详情',
        component: () => import('@/views/User/TextDetails.vue')
      },
      // 查看稿件
      {
        path: 'lookdetails',
        name: 'lookdetails',
        meta: '查看稿件',
        component: () => import('@/views/User/LookDetails.vue')
      }
    ],
  },
]


const router = new VueRouter({
  mode: 'history',
  // scrollBehavior: () => ({ y: 0 }),
  routes
})
// router.beforeEach((to,form,next)=>{

// })
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
