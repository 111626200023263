<template>
    <div>
        <div class="flex" style="align-items: center;margin-bottom: 1rem;">
            <div class="title_box">
                <p class="title">{{ title }}</p>
            </div>
            <el-upload class="upload-demo flex" ref="upload" :action="action" :before-upload="beforeAvatarUpload"
                :on-preview="handlePreview" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                :file-list="fileList" accept=".mp4" :headers="headerObj" :auto-upload="false" :limit="1">
                <el-button slot="trigger" size="small" type="primary">选取视频</el-button>
                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传视频</el-button>
                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>

        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    computed: {
		...mapState(['FillForm'])
	},
    props: {
        title: String,
    },
    mounted() {
        if (this.$store.state.FillForm.video_url!=='') {
            this.videoUrl = this.$store.state.FillForm.video_url
        }
    },
    data() {
        return {
            fullscreenLoading: false,
            videoUrl: '',
            fileList: [],
            headerObj: {
                token: this.$user_info.token,
            },
            action: '/api/common/upload'
        }
    },
    methods: {
        submitUpload() {
            this.$refs.upload.submit();

        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading = true
            // this.$store.state.FillForm.video_url = res.data.url
            this.$emit('getVideo',res.data.url)
            setTimeout(() => {
                this.fullscreenLoading = false
            }, 500)
        },
        handlePreview(file) {
            console.log(file);
        },
        beforeAvatarUpload(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)

            const extension = testmsg === 'mp4'
            const isLt2M = file.size / 1024 / 1024 < 20

            if (!extension) {
                this.$message.error('上传视频类型只能是mp4格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传视频大小不能超过 20MB!')
            }
            return extension && isLt2M
        },
    }
}
</script>

<style lang=scss scoped>
.title_box {
    width: 10rem;
    padding-right: 1rem;
    box-sizing: border-box;

    .title {
        font-size: 1.22rem;
        color: #333;
        // width: 14rem;
        text-align: right;
    }
}

.upload-demo {
    background: none !important;
}

::v-deep .el-upload-list__item:first-child {
    margin-top: 0;
}
</style>