<template>
    <div>
        <button v-if="user_info" @click="is_collectShow()" :class="is_collect ? 'active' : ''">我的收藏</button>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        is_collect: Number
    },
    created() { },
    mounted() {
        this.user_info = this.$util.getLocalStorage('user_info')
        console.log(this.user_info);
    },
    data() {
        return {
            user_info: {}
        }
    },
    methods: {
        is_collectShow() {
            console.log(this.$props);
            if (this.$props.is_collect) {
                this.$emit('collectShow', 0)
            } else {
                this.$emit('collectShow', 1)

            }
        }
    },
}
</script>

<style lang=scss scoped>
button {
    border: 0.1rem solid #DCDFE6;
    padding: 1rem 2rem;
    border-radius: 0.2rem;
    box-sizing: border-box;
    background: #fff;
    color: #333;
    cursor: pointer;
    margin-right: 1rem
}

.active {
    cursor: pointer;
    border-radius: 0.2rem;
    margin: 0 1.5rem;
    padding: 1rem 2rem;
    box-sizing: border-box;
    border: 0;
    color: #fff;
    background: #108CDD;
}
</style>