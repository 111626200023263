// 下载图片
export function downloadByBlob(url, name) {
    let a = document.createElement('a')
    a.style = 'display: none'
    a.target = "_blank"
    a.href = url+''
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
   
}