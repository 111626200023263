const state={
    category_id:1,
    order_status: {
        id: '',
        title: '不限'
    }

}
const mutations={
  
}
export default{
    state,
    mutations
}