<template>
    <div>
        <el-dialog lockScroll :visible.sync="$store.state.passwordShow" :destroy-on-close="true"
            :show-close=true>
            <h3 class="login_title">找回密码</h3>
            <!-- 提交表单 -->
            <div class="from_box">
                <div class="login_box flex">
                    <p>手机号:</p>
                    <el-input @keyup.enter.native="getCode" v-model="form.mobile"></el-input>
                </div>
                <div class="code_box flex">
                    <div class="login_box flex">
                        <p>验证码:</p>
                        <el-input @keyup.enter.native="" v-model="form.code"></el-input>
                    </div>
                    <el-button class="code_but" type="primary" :disabled="codeBut" @click="getCode">{{ codeBut ?
            `重新获取${time}` : `获取验证码` }}</el-button>
                </div>
                <div class="login_box flex">
                    <p>设置密码:</p>
                    <el-input @keyup.enter.native="" type="password" v-model="form.password"></el-input>
                </div>


                <div class="login_box flex">
                    <p>确认密码:</p>
                    <el-input @keyup.enter.native="" type="password" v-model="form.confirm_password"></el-input>
                </div>
                <!-- <div class="verificationCode_box flex">
                    <div class="from">
                        <p>验证码:</p>
                        <el-input v-model="form" placeholder="请输入图片验证码" @keyup.enter.native=""></el-input>
                    </div>
                    <img @click="codeFn" :src="code" alt="" style="width: 120px; cursor: pointer" />
                </div> -->
            </div>
            <button
                style="display: block; width:60%;height: 3rem;border-radius: 0.5rem; border: 0; background: #168ad9;margin: 0 auto; color: #fff;"
                @click="passwordReset">去登录</button>
            <div class="flex" style="margin: 0 auto;width: 60%;justify-content: space-between;">
                <p style="
					color: #999999;
					font-size: 1.2rem;
					margin: 1rem 0;
					text-align: center;
					cursor: pointer;
				" @click="$router.push('/Register')">
                    还没有账号?立即注册
                </p>
                <p style="
                    color: #999999;
					font-size: 1.2rem;
					margin: 1rem 0;
					text-align: center;
					cursor: pointer;" @click="goLogin">返回登陆</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: '',
    created() { },
    mounted() { },
    props: {
        // show: Boolean
    },
    computed: {
		...mapState['passwordShow']
	},
    data() {
        return {
            codeBut: false,
            time: 60,
            form: {
                mobile: '',
                password: '',
                confirm_password: '',
                code: '',
            },

        }
    },
    methods: {
        getCode() {
            let mobile = this.form.mobile
            if (!mobile) {
                return this.$message({
                    offset: 80,
                    message: '请输入手机号',
                    type: 'warning',
                })
            }
            this.curlPost('/api/index/sendcode', {
                mobile: mobile
            }).then(res => {
                console.log(res.data);
                if (res.data.code) {
                    this.codeBut = true
                    let timer = setInterval(() => {
                        this.time -= 1
                        if (this.time == 0) {
                            this.time = 60
                            this.codeBut = false
                            clearInterval(timer)
                        }
                    }, 1000)

                } else {
                    return this.$message({
                        offset: 80,
                        message: res.data.msg,
                        type: 'warning',
                    })
                }
            })
        },
        passwordReset() {
            this.curlPost('/api/user/forget_pwd', this.form).then(res => {
                console.log(res.data);
                if (res.data.code) {
                    this.$message({
                        offset: 80,
                        message: res.data.msg,
                        type: 'success',
                        customClass: 'message_poup_index'
                    })
                    this.goLogin()

                } else {
                    this.$message({
                        offset: 80,
                        message: res.data.msg,
                        type: 'warning',
                        customClass: 'message_poup_index'

                    })
                }
            })
        },
        goLogin() {
            this.$emit('getMobel', this.form.mobile)
        }
    },
}
</script>

<style lang=scss scoped>
// 表单
.from_box {
    width: 60%;
    padding: 1.5rem 0 1rem 0;
    margin: 0 auto;

    .login_box {
        border: 0.1rem solid #dddddd;
        border-radius: 0.6rem;
        align-items: center;
        padding: 0 1rem;
        margin: 0 0 1.5rem 0;

        &:nth-child(4) {
            margin: 0 !important;
        }

        p {
            color: #999999;
            font-size: 1.2rem;
        }

        ::v-deep .el-input {
            width: 70%;
        }

        .el-input__inner {

            border: 0;
            padding: 0 0 0 1rem;
            box-sizing: border-box;
        }
    }

    .code_box {

        border-radius: 0.6rem;
        align-items: center;
        margin: 0 0 1.5rem 0;

        .login_box {
            margin: 0 !important;
        }

        p {
            color: #999999;
        }

        ::v-deep .el-input {
            width: 70%;

        }

        .el-input__inner {

            border: 0.1rem solid #dddddd;
            outline: none;
            padding: 0 0 0 1rem;
            box-sizing: border-box;
        }

        .code_but {
            width: 30%;
        }
    }

    // 验证码
    .verificationCode_box {
        margin-bottom: 1.5rem;
        align-items: center;
        justify-content: space-between;

        .from {
            width: 60%;
            height: 4rem;
            padding: 0 1rem;
            align-items: center;

            // margin: 0 0 1.5rem 0;
            display: flex;
            border: 0.1rem solid #dddddd;
            border-radius: 0.6rem;

            p {
                color: #999999;
                font-size: 1.2rem;
            }

            .el-input__inner {
                border: 0;
                padding: 0;
            }
        }
    }
}
</style>