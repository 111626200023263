<template>
    <div v-if="visible" class="popup" @click.self="closePopup">
        <!-- 弹窗的内容 -->
        <div class="popup-content">
            <p class="popup_p">
                {{ title }} <span v-show="text">({{ text }})</span>
            </p>
            <div class="flex" style="justify-content: flex-end;">
                <button class="up_box" @click.stop="closePopup">取消</button>
                <button class="close_box" @click="confirm">继续</button>
            </div>
        </div>
        <!-- 关闭按钮 -->
    </div>
</template>

<script>
export default {
    props: {
        visible: Boolean,
        title: {
            type: [String, null],
            default: ''
        },
        text: {
            type: [String, null],
            default: ''
        }
        // user_info:{
        //     type:[Object,''],

        // }
    },
    methods: {
        closePopup() {
            this.$emit('close')
        },
        confirm() {
            this.$emit('postData')
        }
    },
    data() {
        return {
        }
    }
}
</script>

<style scoped lang="scss">
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: #fff;
    width:450px;
    /* height: 6rem; */
    padding: 2rem 1rem 1rem 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    text-align: left;
    .popup_p {
        font-weight: bold;
        color: #333;
        font-size: 1.2rem;
        padding: 1rem 0;
        box-sizing: border-box;

        span {
            font-weight: normal;
            font-size: 1.2rem;

        }
    }

    .close_box {
        padding: 0.8rem 2rem;
        box-sizing: border-box;
        background: #2A82E4;
        color: #fff;
        border-radius: 0.2rem;
        border: 0;
        cursor: pointer;
    }

    .up_box {
        padding: 0.8rem 2rem;
        box-sizing: border-box;
        background: #fff;
        color: #2A82E4;
        border: 0.1rem solid #aaa;
        border-radius: 0.2rem;
        cursor: pointer;
        margin-right: 1rem;
    }
}

.close-button {
    margin-top: 10px;
}
</style>