9384c0b6<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box" id="nav" :class="scrollTopNum == 0 ? 'box1' : 'box2'" @mouseleave="navTab(0)"
			@mouseenter="navTab(1)">
			<!--  -->
			<div class="flex nav">
				<div class="flex" style="align-items: center">
					<img :src="imgSrc" alt="" style="width: 147px; margin-right: 50px; cursor: pointer"
						@click="pushHome" />
					<ul class="flex navList">
						<li :class="$store.state.navshow == item.id ? 'active' : ''" v-for="(item, index) in navList"
							:key="index" @mouseleave="childNone(item.id)" @mouseenter="childShow(item.id)"
							@click="routerFn(item.id)">
							{{ item.name }}
						</li>
					</ul>
				</div>

				<div class="login_but flex" v-if="!user_info">
					<button
						style="border: 0.08rem solid #FFFFFF;border-radius: 0.3rem; color: #fff; background: #337ab7;box-sizing: border-box; width: 8.33rem;height: 3.33rem; cursor: pointer;"
						@click="bezel(2)">注册</button>
					<button type="primary"
						style="border: 0; border-radius: 0.3rem; color: #fff; background: #FFA200;box-sizing: border-box; width: 8.33rem;height: 3.33rem;cursor: pointer;"
						@click="bezel(1)">登录</button>
				</div>
				<div class="head_box flex" @click="goUser" v-else-if="user_info">
					<div class="flex heade_name_box">
						<p class="userName" :style="{ color: nameColor ? nameColor : '#fff' }">您好，{{
		user_info.username
	}}</p>
						<div class="head_vip flex">
							<span v-show="user_info.group_id == 2"></span>
							<span v-show="user_info.group_id == 3"></span>
						</div>
					</div>
					<!-- <img :src="user_info.avatar" alt="" class="avatar" /> -->
					<el-button type="primary">进入会员中心</el-button>

				</div>
			</div>
			<div class="child_box" v-show="childUl" @mouseleave="childUl = false" @mouseenter="childUl = true">
				<ul class="childUl flex">
					<li :class="childTitle == item ? 'active' : ''" @click.stop="childRoute(item.id)"
						v-for="(item, index) in child1" :key="index">{{ item.title }}</li>
				</ul>
			</div>
			<div class="child_box2" v-show="childUl2" @mouseleave="childUl2 = false" @mouseenter="childUl2 = true">
				<ul class="childUl flex">
					<li :class="childTitle == item ? 'active' : ''" v-for="(item, index) in child2" :key="index"
						@click.stop="childRoute(item.id)">{{ item.title }}</li>
				</ul>
			</div>
			<!--  -->
			<div class="child_box3" v-show="childUl3" @mouseleave="childUl3 = false" @mouseenter="childUl3 = true">
				<ul class="childUl flex">
					<li :class="childTitle == item ? 'active' : ''" @click.stop="childRoute(item.id)"
						v-for="(item, index) in child3" :key="index">{{ item.title }}</li>
				</ul>
			</div>
		</div>

		<el-dialog modal lockScroll :visible.sync="$store.state.loginShow" :show-close="false" :destroy-on-close="true">
			<h3 class="login_title">欢迎进入<span>企宣通</span></h3>
			<div class="loginBox flex">
				<p :class="loginNum == 1 ? 'loginShow' : ''" @click="loginFn(1)">
					登录
				</p>
				<p :class="loginNum == 2 ? 'loginShow' : ''" @click="loginFn(2)">
					注册
				</p>
			</div>
			<!-- 提交表单 -->
			<div class="from_box">
				<div class="login_box flex">
					<p>手机号:</p>
					<el-input @keyup.enter.native="submitForm" v-model="form.mobile" placeholder="请输入手机号"></el-input>
				</div>
				<div class="login_box flex">
					<p>密码:</p>
					<el-input @keyup.enter.native="submitForm" type="password" v-model="form.password"
						placeholder="请输入密码"></el-input>
				</div>
				<div class="verificationCode_box flex">
					<div class="from">
						<p>验证码:</p>
						<el-input v-model="form.verificationCode" placeholder="请输入图片验证码"
							@keyup.enter.native="submitForm"></el-input>
					</div>
					<img @click="codeFn" :src="code" alt="" style="width: 120px; cursor: pointer" />
					<!-- <VueImage @getIdentifyCode="getIdentifyCodes" /> -->
				</div>
				<!-- <div class="flex">
					<el-checkbox v-model="form.clause"></el-checkbox>
					<p style="color: #999999; font-size: 1.2rem; margin-left: 0.8rem">
						我已阅读并同意<span style="font-size: 1.2rem; color: #168ad9">《企宣通服务条款》</span>
					</p>
				</div> -->
			</div>
			<button
				style="display: block; width:60%;height: 3rem;border-radius: 0.5rem; border: 0; background: #168ad9;margin: 0 auto; color: #fff;"
				@click="submitForm">登录</button>
			<div class="flex" style="justify-content: space-between;width: 60%;margin: 0 auto;">
				<p style="
					color: #999999;
					font-size: 1.2rem;
					margin: 1rem 0;
					text-align: center;
					cursor: pointer;
				" @click="$router.push('/Register')">
					还没有账号?立即注册
				</p>
				<p @click="goforgetPassword" style="color: #999999;
					font-size: 1.2rem;
					margin: 1rem 0;
					text-align: center;
					cursor: pointer;">忘记密码</p>
			</div>
		</el-dialog>
		<forgetPassword @getMobel="getMobel" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { goAi } from '@/util/goAi'
import forgetPassword from '../components/forgetPassword.vue'
export default {
	name: '',
	components: {
		forgetPassword
	},
	props: {
		nameColor: String,
	},
	created() {
		// console.log('nav created:', this.user_info);
		window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
	},
	mounted() {
		this.handleScroll()
		this.codeFn()
		this.getMediaNav()
		this.user_info = this.$util.getLocalStorage('user_info')
	},
	computed: {
		...mapState['activeTitle', 'ActiveChild', 'navShow']
	},
	watch: {

	},
	data() {
		return {
			imgSrc: require('@/assets/nav/black.png'),
			scrollTopNum: 0, // 计算器
			navList: [
				{
					id: 0,
					name: '首页',
				},
				{
					id: 1,
					name: '媒介投放',
				},

				{
					id: 2,
					name: '一言一文',
				},
				{
					id: 3,
					name: '享视界'
				},
				// {
				// 	id: 3,
				// 	name: 'AI助手',
				// },
				{
					id: 4,
					name: '产品合作',
				},
				{
					id: 5,
					name: '技巧指南',
				},
				{
					id: 6,
					name: '关于我们',
				}
			],
			child1: [],
			child2: [{
				id: 101,
				title: '媒体发布',
			}, {
				id: 102,
				title: '技术开发',
			}, {
				id: 103,
				title: '视频制作',
			}, {
				id: 104,
				title: '可信网站',
			}, {
				id: 105,
				title: '一言一文'
			}, {
				id: 106,
				title: '享视界',
			}],
			child3: [{
				id: 107,
				title: '营销技巧',
			}, {
				id: 108,
				title: '拍摄技巧',
			}, {
				id: 109,
				title: '写稿技巧',
			}],
			childTitle: '',
			loginNum: 1,
			form: {
				mobile: '', //账号
				password: '', //密码
				clause: false, //服务条款
				verificationCode: '',
			},
			code: '', //生成的验证码
			fullscreenLoading: false,
			show: false,
			user_info: {},
			childUl: false,
			childUl2: false,
			childUl3: false,
			loginShow: this.$route.params.loginShow ? 1 : 0,

		}
	},
	methods: {
		navTab(status) {
			if (status == 1) {
				this.imgSrc = require('@/assets/nav/white.png')
			} else {
				this.imgSrc = require('@/assets/nav/black.png')
			}
		},
		goforgetPassword() {
			this.$store.state.passwordShow = true
			this.$store.state.loginShow = false
			this.form = {
				mobile: '', //账号
				password: '', //密码
				clause: false, //服务条款
				verificationCode: '',
			}
		},
		/*修改密码过来后*/
		getMobel(mobile) {
			this.$store.state.passwordShow = false

			if (mobile) {
				this.form.mobile = mobile
			}
			this.bezel(1)
		},
		getMediaNav() {
			this.curlGet('/api/medium/init').then(res => {
				if (res.data.code) {
					this.child1 = res.data.data
				}
			})
		},
		goUser() {
			this.activeTitle = '首页'
			this.ActiveChild = ''
			this.$router.push('/user/userIndex')
		},
		// 登录-注册选项卡
		loginFn(num) {
			this.loginNum = num
			if (this.loginNum == 2) {
				console.log(num)
				this.$store.state.loginShow = false
				this.$router.push({
					path: '/register',
				})
			} else {
			}
		},
		bezel(id) {
			this.loginNum = id
			if (this.loginNum == 1) {
				this.$store.state.loginShow = true
				this.codeFn()
			} else {
				this.$router.push({
					path: '/register',
				})
			}
		},
		// 路由跳转
		routerFn(index) {
			this.$store.state.navshow = index
			switch (index) {
				case 0:
					this.$router.push('/')
					break;
				case 1:
					this.$router.push('/media/News')
					break;
				case 2:
					this.$router.push('/wordNav')
					break;
				case 3:
					this.$router.push('/aiNav')
					break;
				case 4:
					this.$router.push('/product')
					break;
				case 5:
					this.$router.push('/guide')
					break;
				case 6:
					this.$router.push('/about')
					break;
				case 7:
					this.$router.push('/enjoy')
				default:
					break
			}
		},
		// 获取页面滚动距离
		handleScroll() {
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			// console.log(scrollTop, '滚动距离')
			this.scrollTopNum = scrollTop
			if (!this.scrollTopNum) {
				this.imgSrc = require('@/assets/nav/black.png')
			} else {
				this.imgSrc = require('@/assets/nav/white.png')
			}
		},
		submitForm() {
			if (this.form.mobile == '') {
				return this.$message({
					message: '请输入账号',
					type: 'warning',
				})
			} else if (this.form.password == '') {
				return this.$message({
					message: '请输入密码',
					type: 'warning',
				})
			} else if (this.verificationCode == '') {
				return this.$message({
					message: '验证码不能为空!',
					type: 'warning',
				})
			} // else if (!this.form.clause) {
			// 	console.log(this.form.clause)
			// 	return this.$message({
			// 		message: '请阅读并同意服务条款',
			// 		type: 'warning',
			// 	})
			// }
			this.curlPost('/api/user/login', {
				account: this.form.mobile,
				password: this.form.password,
				captcha: this.form.verificationCode,
			}).then((res) => {
				if (res.data.code) {
					this.$nextTick(() => {
						localStorage.setItem('signTime', Date.now())
						this.$util.setLocalStorage('user_info', res.data.data.userinfo, 200) // 写入local storage
						this.$message({
							offset: 80,
							message: res.data.msg,
							type: 'success',
						});
						this.form = {
							mobile: '', //账号
							password: '', //密码
							clause: false, //服务条款
							verificationCode: '',
						}
						this.code = '' //生成的验证码
						this.$store.state.loginShow = false

						// this.$user_info = res.data.data.userinfo
						// this.$router.push('/user/userIndex')
						location.reload()
					})
				} else {
					this.codeFn()
					return this.$message({
						offset: 80,
						message: res.data.msg,
						type: 'warning',
					})
				}
			})
		},
		codeFn() {
			this.curlGetCode('/api/common/captcha').then((res) => {
				if (res.status == 200) {
					// 以下为精髓
					let blob = new Blob([res.data]) // 返回的文件流数据
					let url = window.URL.createObjectURL(blob) // 将他转化为路径
					this.code = url // 将转换出来的路径赋值给变量，其实和上一步结合就可以
				}
			})
		},
		pushHome() {
			this.$store.state.navshow
			this.$router.push('/')
		},
		// 控制子菜单的显示隐藏
		childShow(id) {
			if (id == 1) {
				this.childUl = true
			} else if (id == 4) {
				this.childUl2 = true
			} else if (id == 5) {
				this.childUl3 = true
			}
		},
		// 控制子菜单的显示隐藏
		childNone(id) {
			if (id == 1) {
				this.childUl = false
			} else if (id == 4) {
				this.childUl2 = false
			} else if (id == 5) {
				this.childUl3 = false
			}
		},
		childRoute(id) {
			this.childTitle = id
			console.log(id);
			switch (id) {
				case 1:
					this.$router.push('/media/News')
					break
				case 2:
					this.$router.push('/media/WeMedia')
					break
				case 3:
					this.$router.push('/media/PaperMedia')
					break
				case 4:
					this.$router.push('/media/OfficialAccounts')

					break
				case 5:
					this.$router.push('/media/Microblog')

					break
				case 6:
					this.$router.push('/media/RedBook')
					break
				case 7:
					this.$router.push('/media/ShortVideo')
					break
				case 99:
					this.$router.push('/media/SetMedia')
					break
				case 101:
					this.$router.push('/media/News')
					break
				case 102:
					this.$router.push('/technical')
					break
				case 103:
					this.$router.push('/product')
					break
				case 104:
					this.$router.push('/credible')
					break
				case 105:
					this.$router.push('/word')
					break
				case 106:
					this.$router.push('/enjoy')
					break
				case 107:
					this.$router.push({
						path: '/guide',
						query: {
							show: 0
						}
					})
					break
				case 108:
					this.$router.push({
						path: '/guide',
						query: {
							show: 1
						}
					})
					break
				case 109:
					this.$router.push({
						path: '/guide',
						query: {
							show: 2
						}
					})
					break
				default:
					break
			}
		},
		// 去功能选择页面
		async goAiNav() {
			const aiStatus = await goAi()
			if (aiStatus == 'ok') {
				this.$router.push('/aiNav')
			} else {
				return this.$store.state.loginShow = true
			}
		},
	},

	// 滚动重置
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll)
	},
}
</script>

<style lang=scss scoped>
@import '@/scss/nav';
@import '@/scss/nav_h5';
</style>