import api from './index'

export function get(url, params) {
    return api({
        url: url, // 请求地址
        method: 'GET', // 请求方式
        params: params //(一般get请求，我们习惯使用params属性来传参）
        // 注意：data，和 params 两个属性传参使用，并不是固定的，也可以调换使用。
    })
}

export function getCode(url, params) {
    return api({
        url: url, // 请求地址
        method: 'GET', // 请求方式
        responseType: 'blob',
        params: params //(一般get请求，我们习惯使用params属性来传参）
        // 注意：data，和 params 两个属性传参使用，并不是固定的，也可以调换使用。
    })
}

export function post(url, data) {
    return api({
        url: url, // 请求地址
        method: 'POST', // 请求方式
        data: data, // (一般post请求，我们习惯使用 data属性来传参)
        // params: params //(一般get请求，我们习惯使用params属性来传参）
        // 注意：data，和 params 两个属性传参使用，并不是固定的，也可以调换使用。
    })
}

export function curlGet(url, params) {
    return api({
        url: url, // 请求地址
        method: 'GET', // 请求方式
        params: params //(一般get请求，我们习惯使用params属性来传参）
        // 注意：data，和 params 两个属性传参使用，并不是固定的，也可以调换使用。
    })
}

export function curlGetCode(url, params) {
    return api({
        url: url, // 请求地址
        method: 'GET', // 请求方式
        responseType: 'blob',
        params: params //(一般get请求，我们习惯使用params属性来传参）
        // 注意：data，和 params 两个属性传参使用，并不是固定的，也可以调换使用。
    })
}

export function curlPost(url, data) {
    return api({
        url: url, // 请求地址
        method: 'POST', // 请求方式
        data: data, // (一般post请求，我们习惯使用 data属性来传参)
        // params: params //(一般get请求，我们习惯使用params属性来传参）
        // 注意：data，和 params 两个属性传参使用，并不是固定的，也可以调换使用。
    })
}